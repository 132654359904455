// $primary-color: #27253b;
$primary-color: #00211c;
$secondary-color: #ffffff;
$tertiary-color: #ffffff;
// $button-color: #f88600;
// $content-color: #27253b;
$content-color: #00211c;
$button-color: #00211c;
$danger-color: red;
$grey: rgba(0, 0, 0, 0.45);
$black: rgb(38, 38, 38);
$white: #fff;
$small: 5px;
$medium: 25px;
$large: 50px;

.reward-modal-confirm-btns {
    .ant-modal-confirm-btns {
        display: none;
    }
}

.ant-typography {
    color: $content-color;
}

.copy-link-button {
    .copy-link:hover {
        text-decoration: underline;
        text-decoration-color: white;
    }
}

.button-logout-header {
    background: none;
    border: none;
    color: white;
}

.ant-select .ant-select-selector {
    border-radius: 8px !important;
    background-color: #f3f5f6 !important;
}

.ant-layout-header {
    background-color: $primary-color;
}

.ant-modal-content {
    border-radius: 1rem !important;
}

.ant-modal-header {
    border-radius: 1rem !important;
}

.ant-btn-primary {
    background: $button-color;
    border: none;
    border-radius: 8px;
}

.ant-btn {
    box-shadow: none;
}

.ant-btn-primary:hover {
    background: $button-color;
    border: 1px solid $primary-color;
}

.ant-menu-inline .ant-menu-item::after {
    border: 0;
}

.ant-btn-primary:hover {
    background: $white;
    border-color: $primary-color;
    color: $black;
}

.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
    color: $button-color;
    border-color: $button-color;
}

.ant-picker:hover,
.ant-picker-focused,
.ant-input:hover,
.ant-upload,
.ant-upload-select-picture-card:hover {
    border-color: $tertiary-color;
}

.ant-upload.ant-upload-select-picture-card:hover {
    border-color: $tertiary-color;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: $tertiary-color;
}

.ant-input:focus,
.ant-input-focused {
    border-color: $tertiary-color;
}

.ant-btn-primary:focus {
    background: $button-color;
    color: $white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:hover {
    color: $black;
}

.ant-switch-checked {
    background: $button-color;
}

.ant-tabs-ink-bar {
    background: $tertiary-color !important;
}

.ant-menu {
    background: $primary-color;
}

.nav-divider {
    background: white;
}

.rounded {
    border-radius: 5px;
}

html,
body,
#root,
#root > div:first-of-type,
.ant-layout,
.ant-layout-sider,
.main {
    --antd-wave-shadow-color: $tertiary-color;
    height: 100%;
}

.radio-feedback-hidden {
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.radio-feedback-visible {
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

@media (-webkit-device-pixel-ratio: 1.25) {
    :root {
      zoom: 0.8;
    }
  }
  
  
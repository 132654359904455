@import 'src/base';

.flex-loader {
    height: 100vh;
    flex: 1;
    display: flex;
    background-color: transparent;

    .ant-layout {
        flex: 1;
        justify-content: center;
        background-color: transparent;
    }
}
